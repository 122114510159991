import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Paginator, PostsRoute, UsersRoute } from '@const';
import { FilterCmpOption, FilterCmpSearchKey } from '@components/filters';
import { QueryService } from '@services/query';
import { QueryFilters, QueryParams } from '@interfaces';
import { PostsService } from '../../service/posts.service';
import { Post } from '../../interfaces/post.interface';
import { SnackbarService } from '@services/snackbar';

type PostProps = keyof Post;

@Component({
  selector: 'app-posts-list',
  templateUrl: './posts-list.component.html',
  styleUrls: ['./posts-list.component.scss'],
  providers: [QueryService],
})
export class PostsListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // pass predefined query filters
  @Input() queryFilters: QueryFilters = {};

  Route = PostsRoute;
  Paginator = Paginator;
  UsersRoute = UsersRoute;

  dataSource = new MatTableDataSource<Post>([]);
  displayedColumns: PostProps[] = [
    'text',
    'tags',
    'user',
    'isPublic',
    'createdAt',
    'corporation',
  ];

  filterSearchKeys: FilterCmpSearchKey<Post>[] = ['text'];
  filters: FilterCmpOption<Post>[] = [];

  acts = {
    isLoading: true,
  };

  constructor(
    private postsSrv: PostsService,
    private querySrv: QueryService,
    private snackBarSrv: SnackbarService,
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const request = (queryParams: QueryParams) => {
      if (this.queryFilters) {
        queryParams.filters = { ...queryParams.filters, ...this.queryFilters };
      }
      return this.postsSrv.getAll(queryParams);
    };

    this.querySrv
      .register({
        sort: this.sort,
        paginator: this.paginator,
        request,
        acts: this.acts,
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        ({ data }) => (this.dataSource = new MatTableDataSource<Post>(data)),
      );
    this.querySrv.initSearch();
  }

  filterOnRequest(params: QueryParams) {
    this.paginator.pageIndex = 0;
    this.querySrv.initSearch(params);
  }

  ngOnDestroy() {
    this.querySrv.destroy();
  }
}
