<div class="prompt-wrapper">
  <app-universal-button
      icon="close"
      color="default"
      mat-dialog-close
      class="close-btn"
  ></app-universal-button>

  <!-- TITLE -->
  <h2 mat-dialog-title>Please select fields for export</h2>

  <!-- CONTENT -->
  <div mat-dialog-content>
    <div>
      <ul>
        <li>
          <input type="checkbox" id="name" [(ngModel)]="fields.name">
          <label for="name">User name</label>
        </li>
        <li>
          <input type="checkbox" id="email" [(ngModel)]="fields.email">
          <label for="email">User email</label>
        </li>
        <li>
          <input type="checkbox" id="bio" [(ngModel)]="fields.bio">
          <label for="bio">Bio</label>
        </li>
        <li>
          <input type="checkbox" id="status" [(ngModel)]="fields.status">
          <label for="status">Status</label>
        </li>
        <li>
          <input type="checkbox" id="dueDate" [(ngModel)]="fields.dueDate">
          <label for="dueDate">Due date</label>
        </li>
        <li>
          <input type="checkbox" id="breastfeedingGoal" [(ngModel)]="fields.breastfeedingGoal">
          <label for="breastfeedingGoal">Breastfeed end</label>
        </li>
        <li>
          <input type="checkbox" id="maternityLeaveEnd" [(ngModel)]="fields.maternityLeaveEnd">
          <label for="maternityLeaveEnd">Maternity leave end</label>
        </li>
        <li>
          <input type="checkbox" id="company" [(ngModel)]="fields.companyName">
          <label for="company">Company</label>
        </li>
        <li>
          <input type="checkbox" id="companyHr" [(ngModel)]="fields.companyHrName">
          <label for="companyHr">Company HR</label>
        </li>
        <li>
          <input type="checkbox" id="coproporation" [(ngModel)]="fields.corporation">
          <label for="coproporation">Corporation</label>
        </li>
        <li>
          <input type="checkbox" id="userType" [(ngModel)]="fields.type">
          <label for="userType">User type</label>
        </li>
        <li>
          <input type="checkbox" id="role" [(ngModel)]="fields.role">
          <label for="role">Role</label>
        </li>
        <li>
          <input type="checkbox" id="created_at" [(ngModel)]="fields.createdAt">
          <label for="created_at">Profile creation date</label>
        </li>
        <li>
          <input type="checkbox" id="tribes" [(ngModel)]="fields.tribes">
          <label for="tribes">Tribes</label>
        </li>
        <li>
          <input type="checkbox" id="code" [(ngModel)]="fields.code">
          <label for="code">Code</label>
        </li>
      </ul>
    </div>
    <app-universal-button btnTitle="Download" icon="save" (click)="download()" [disabled]="!downloadPossible()"></app-universal-button>
  </div>
</div>








