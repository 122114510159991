import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { finalize } from 'rxjs/operators';

import { QueryFilters, QueryParams } from '@interfaces';
import { Paginator, UsersRoute } from '@const';
import { FilterCmpOption, FilterCmpSearchKey } from '@components/filters';
import { QueryService } from '@services/query';
import { User } from '../..';
import { UserService } from '../../service';
import { SnackbarService } from '@services/snackbar';
import { MatDialog } from '@angular/material/dialog';
import { CSVModalComponent } from './csv-modal/csv-modal.component';
import { RoleService } from '../../../role/service/role.service';

type UserProps = keyof User;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [QueryService],
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // passed predefined query filters
  @Input() queryFilters: QueryFilters = {};

  Route = UsersRoute;
  Paginator = Paginator;

  dataSource = new MatTableDataSource<User>([]);
  displayedColumns: UserProps[] = [
    'avatar',
    'name',
    'role',
    'companyName',
    'email',
    'status',
    'code',
    'createdAt',
  ];

  filterSearchKeys: FilterCmpSearchKey<User>[] = [
    'name',
    'email',
    'phone',
    { value: 'companyName', viewValue: 'company name' },
  ];
  filters: FilterCmpOption<User>[] = [
    {
      value: 'dueDate',
      viewValue: 'due date',
      type: 'date',
    },
    {
      value: 'breastfeedingGoal',
      viewValue: 'breastfeed end',
      type: 'date',
    },
    {
      value: 'maternityLeaveEnd',
      viewValue: 'maternity leave end',
      type: 'date',
    },
  ];

  acts = {
    isLoading: true,
  };

  constructor(
    private userSrv: UserService,
    private roleService: RoleService,
    private querySrv: QueryService,
    private snackBarSrv: SnackbarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.roleService.list()
    .pipe(finalize(() => (this.acts.isLoading = false)))
    .subscribe(result => {
      this.filters = [...this.filters,
        { value: 'role', viewValue: 'user role', type: 'select', optionsArr:
          result.map(i => {
            return {
              value: i._id,
              viewValue: this.roleText(i.title),
            };
          })
        }
      ];
    });
  }

  ngAfterViewInit() {
    const request = (params: QueryParams) => {
      if (this.queryFilters) {
        params.filters = { ...params.filters, ...this.queryFilters };
      }

      // because of backend namingFilterEqual
      if (this.queryFilters?.corporation) {
        params.filters.corporateId = params.filters.corporation;
        delete params.filters.corporation;
      }

      params.filters.companyName = params.filters.companyname;
      // params.filters.role = {equal: `6007de6ab1161900127071e6`};
      return this.userSrv.getAll(params);
    };

    this.querySrv
      .register({
        sort: this.sort,
        paginator: this.paginator,
        request,
        acts: this.acts,
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        ({ data }) => (this.dataSource = new MatTableDataSource<User>(data)),
      );
    this.querySrv.initSearch();
  }

  filterOnRequest(params: QueryParams) {
    this.paginator.pageIndex = 0;
    this.querySrv.initSearch(params);
  }

  add() {
    this.snackBarSrv.info();
  }

  ngOnDestroy() {
    this.querySrv.destroy();
  }

  csv_export() {
    const dialogRef = this.dialog.open(CSVModalComponent, {});
    dialogRef.afterClosed().subscribe((fields) => {
      if (fields && (typeof fields === 'object' && Object.values(fields).some(e => !!e))) {
        const checkedFields = Object.entries(fields)
          .filter(([k, v]) => !!v)
          .map(([k, v]) => k)
          .join();

        this.userSrv.getCSV(checkedFields).subscribe((data) => {
          const blob = new Blob([data], { type: 'text/csv' });
          const a = document.createElement('a');

          a.download = 'users.csv';
          a.href = URL.createObjectURL(blob);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      }
    });
  }

  roleText(role: string): string {
    return role === 'user' ? 'mom' : role;
  }
}
